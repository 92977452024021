@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Inconsolata:wght@400;700&display=swap');

:root {
  --primary-color: #000;
  --secondary-color: #878787;
  --border-color: #dee2e6;
  --text-color: #34363E;
  --nav-bar-height: 115px;
  --nav-bar-width: 80px;
}

html {
  scroll-behavior: smooth;
}

 body {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.12em;
  line-height: 1.9;
  font-size: 1em;
  color: var(--text-color);;
  width: 100%;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

 .pages {
   width: calc(100% - (var(--nav-bar-width)));
 }

.page {
  /*padding-bottom: 10px;*/
  /*padding-top: 10px;*/
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page .page-content *:first-child {
  margin-top: 0;
  line-height: 27px;
}

.page:first-child {
  /*min-height: calc(100vh - var(--nav-bar-height) * 2);*/
/*  display: flex;
  justify-content: center;
  align-items: center;*/
}

/*@media (max-height: 570px) {
  .page {
    min-height: unset;
    margin-top: 20px;
  }
}*/

@media (max-width: 1279px) and (max-height: 570px)  {
  .page {
    min-height: unset;
    margin-top: 40px;
  }

  .page .page-content *:first-child {
    margin-top: 0;
  }
}

@media (max-height: 768px) {
  .page {
    min-height: unset;
    margin-top: 40px;

  }

  .page .page-content *:first-child {
    margin-top: 0;
  }
}

@media (max-width: 400px) {
  .page {
    min-height: unset;
    margin-top: 40px;
  }

  .page .page-content *:first-child {
    margin-top: 0;
  }
}

h1 {
  line-height: 1.25;
  margin-top: 0;
  font-size: calc(2.8vw + 2.8vh);
}

h2 {
  font-size: 2em;
}

/*.anchor:before {
  !*border-bottom: 8px solid var(--border-color);*!
  content: '';
  width: 30%;
  height: 40px;
  display: block;
}*/

.anchor {
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-transform: uppercase;
  background: var(--text-color);
  color: #fff;
  padding: 10px 5px;
}

@media (max-width: 1279px) and (max-height: 570px)  {
  .anchor {
    transform: unset;
    writing-mode: unset;
    padding:10px;
    width:fit-content;
  }
}

/*
@media (max-height: 768px) {
  .anchor {
    transform: unset;
    writing-mode: unset;
    background: none;
    color: var(--text-color);
    padding:0;
  }
}
*/

@media (max-width: 991px) {
  .anchor {
    transform: unset;
    writing-mode: unset;
    padding:10px;
    width:fit-content;
  }
}

h3 {
  font-size: 1.5em;
}

h3 .fas {
  font-size: 12px;
  color: var(--secondary-color);
}

h3 > small {
  font-weight: normal;
  font-size: x-small;
  padding:0;
  line-height: 1;
  display: block;
  color: var(--secondary-color);
}

h5 {
  font-size: 1em;
  margin-bottom: 10px;
}

.indent {
  padding-left: 15px;
}

.comment {
  border-left: 7px solid var(--border-color);
  padding-left: 15px;
}

.paragraph-header {
  font-style: italic;
}

.sub-heading {
  font-size: 40px;
  font-weight: normal;
  color: #999;
  margin: 0;
  padding: 0;
  display: block;
}

a {
  text-decoration: underline;
  color: var(--text-color);
}

a.icon {
  text-decoration: unset;
}

.secondary-color {
  color: var(--secondary-color);
}

.word-definition {
  font-family: 'Inconsolata', monospace;
  margin-bottom: 2em;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 3px;
}

pre {
  font-family: monospace;
  font-size: 14px;
  letter-spacing: normal;
}

pre.code {
  font-family: monospace;
  font-size: 14px;
  background-color: #f4f4f4;
  margin: 4em auto;
  padding: 0.5em;
  border-radius: .25em;
  /*box-shadow: .1em .1em .5em rgba(0, 0, 0, .2);*/
  line-height: 0;
  counter-reset: line;
  overflow-x: scroll;
  overflow-y: hidden;
}

pre.code > span {
  display: block;
  line-height: 1.5rem
}

pre.code > span:before {
  counter-increment: line;
  content: counter(line);
  display: inline-block;
  border-right: 1px solid #ddd;
  padding: 0 .5em;
  margin-right: .5em;
  color: #888;
}

.word-definition-phrase {
  font-weight: bold;
  line-height: 1em;
}

.word-definition-phrase > #cursor {
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: -1px;
}

.word-definition-pronunciation, .code-comment {
  /*font-size: small;*/
  /*
    font-style: italic;
  */
  color: var(--secondary-color);
}

.code-keyword {
  color:#008000;
  font-weight: bold;
}

.code-string-literal {
  color:#BA2121;
}

.word-definition-title {
  font-style: italic;
  font-size: small;
}

.word-definition-meaning {
  font-size: small;
}

.badge {
  display: inline-block;
  padding: 0 8px;
  background: var(--text-color);
  border-radius: 4px;
  color: #fff;
  font-size: small;
  margin: 3px;
  word-break: keep-all;
  white-space: nowrap;
}

.section {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.footer {
  min-height: 300px;
  background: var(--primary-color);
  width: 100%;
  margin-top: 50px;
  font-size: small;
  color: #fff;
}

.footer a {
  color: #fff;
  text-decoration: underline;
}

.footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tooltip {
  position: relative;
  /*border-bottom: 1px dotted var(--secondary-color);*/
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  font-weight: normal;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.contact-details {
  background: #efefef;
  list-style: none;
  padding: 10px;
  margin: 0;
}

.images {
}

.images img {
  height: 220px;
  display: inline-block;
  border-radius: 5px;
}

.arrow {
  position: absolute;
  left: calc(50% - 100px);
  bottom: 40px;
  cursor: pointer;
  color: #fff;
  background: var(--text-color);
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  font-size: small;
  /*transform: rotate(180deg);*/
  /*writing-mode: vertical-lr;*/
  text-transform: uppercase;
}

.nav {
  width: var(--nav-bar-width);
  /*padding-left: 20px;*/
  height:100%;
  /*background:var(--text-color);*/
  position: fixed;
  right: 0;
}

.nav-item {
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-transform: uppercase;

  /*color: #fff;*/
  /*margin-bottom: calc((100vh - 600px) / 6);*/
  height: calc(100vh / 6);
  /*border: 1px solid white;*/
  font-weight: bold;
  font-size: small;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.nav-item:after {
  content: '';
  height:100%;
  border-right: 1px dashed #bbb;
  display: block;
}

.nav-item:before {
  content: '';
  height:100%;
  border-right: 1px dashed #bbb;
  display: block;
}

.nav-item.active {
  color: #ff0000;
}

.navigation-link-top {
  position: absolute;
  align-self: flex-start;
  left: calc(50% - 60px);
  margin-top: 30px;
  /*top: -10px;*/
  /*justify-self: center;*/
  cursor: pointer;
  color: #fff;
  background: var(--text-color);
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  font-size: small;
  /*transform: rotate(180deg);*/
  /*writing-mode: vertical-lr;*/
  text-transform: uppercase;
}
.navigation-link-bottom {
  position: absolute;
  align-self: flex-end;
  left: calc(50% - 60px);
  margin-top: -30px;
  /*top: -10px;*/
  /*justify-self: center;*/
  cursor: pointer;
  color: #fff;
  background: var(--text-color);
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  font-size: small;
  /*transform: rotate(180deg);*/
  /*writing-mode: vertical-lr;*/
  text-transform: uppercase;
}

.arrow ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.arrow ul li {
  display: inline-block;
  margin-left: 10px;
}

@media (max-width: 1279px) and (max-height: 570px)  {
  .arrow {
    display: none;
  }
}

@media (max-height: 768px) {
  .arrow {
    display: none;
  }
}

@media (max-width: 400px) {
  .arrow {
    display: none;
  }
}

.underline {
  text-decoration: underline;
  text-decoration-color: #00bcd4;
}

.img-me {
  width: 70%;
  border-radius: 50%;
  border: 2px solid #000;
  display: inline-block;
  vertical-align: middle;
}
